@import 'normalize.css';
@import 'sanitize.css';

a,
a:visited,
a:hover,
a:active,
a:link {
  text-decoration: none;
  color: inherit;
}

p,
ul,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

button {
  outline: none;
}
* {
  box-sizing: border-box;
}

@font-face {
  font-family: ProximaNova;
  src: url('/assets/ProximaNovaBold.otf');
}
